<template>
  <div>
    <div class="return" v-if="clienId" @click="back">
      <img class="backImg" src="@/assets/images/back.png" alt />
    </div>
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
        <div>
          <el-button v-if="detail.early_warning == 1 && detail.early_warning_result == 0" class="mb10" type="primary" size="mini" @click="getTextType('预警')">处理预警</el-button>
        <el-button v-if="detail.accountability == 1 && detail.accountability_result == 0" class="mb10" type="primary" size="mini" @click="getTextType('追责')">处理追责</el-button>
        </div>
      </div>
      <el-descriptions class="margin-top" :column="4" border>
        <el-descriptions-item>
          <template slot="label">回访日期</template>
          {{detail.return_visit_time}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">顾客姓名</template>
          {{detail.customer_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">归属市场</template>
          {{detail.market_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">归属门店</template>
          {{detail.shop_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">责任护理师</template>
          {{detail.nurse_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">回访结果</template>
              <span v-if="detail.result == 0">暂无评价</span>
              <span v-if="detail.result == 1">满意</span>
              <span v-if="detail.result == 2">一般</span>
              <span v-if="detail.result == 3">不满意</span>
              <span v-if="detail.result == 4">投诉 ({{detail.result_name}})</span>
              <span v-if="detail.result == 5">流失</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">问题分类</template>
          {{detail.setting_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">综合评分</template>
          {{detail.total}}
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label" >效果问题</template>
          {{detail.effect_problem_name}}
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label">服务问题</template>
          {{detail.service_issues_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">	是否预警</template>
          {{detail.early_warning==0?'否':'是'}}
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label"> 预警内容</template>
          {{detail.early_warning_content?detail.early_warning_content:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template slot="label"> 预警处理内容</template>
          {{detail.early_warning_huifu?detail.early_warning_huifu:'暂无'}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">	是否追责</template>
          {{detail.accountability==0?'否':'是'}}
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label"> 追责内容</template>
          {{detail.accountability_content?detail.accountability_content:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template slot="label" > 追责处理内容</template>
          {{detail.accountability_huifu?detail.accountability_huifu:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label"> 回访内容</template>
          {{detail.return_visit_content?detail.return_visit_content:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label"> 回访备注</template>
          {{detail.remarks?detail.remarks:'暂无'}}
        </el-descriptions-item>
      </el-descriptions>
      
    </div>
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />回访记录
        </div>
      </div>
      <activity
              handle-type="order"
              :activity-list="follow"
              handleType="case"
              @followAdd="followAdd"
            />
    </div>
    <el-dialog
      :title="`处理${textType}`"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%">
      <el-form
          ref="rateDetail"
          label-position="right"
          label-width="180px"
          :rules="rules"
          :model="rateDetail"
        >
          <el-form-item label="处理内容" prop="result_content" >
             <el-input class="w500" type="textarea" v-model="rateDetail.result_content" maxlength="50"></el-input>
          </el-form-item>
          
         <div class="footerBtn">
            <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitForm('rateDetail')"
          >完成，提交</el-button>
         </div>
        </el-form>
      
    </el-dialog>

  </div>
</template>

<script>
import activity from '@/components/Activity';
import { caseDetail,addCaseLog,psCase,psTj } from "@/api/case";
import { readReturnVistT,addReturnVistTLog,saveReturnVistT } from "@/api/againVisit";
class RateDetail {
    result_content='';
}
class Rules {
    result_content = [
        { required: true, message: '请填写处理内推', trigger: 'blur' }
    ];
}
export default {
  components: {
    activity
  },
  data() {
    return {
      rateDetail: new RateDetail(),
      rules: new Rules(),
      detail: {},
      follow: [], // 跟进记录
      dialogVisible: false,
      colors: ['#99A9BF', '#33bea7df', '#33bea7'],
      textType:'',
      followDetail:'',
      clienId: "",
    };
  },
  mounted() {
    this.clienId = this.$route.query.clienId;
    this.getDetail();
  },
  methods: {
    // 获取详情信息
    getDetail() {
      readReturnVistT({ id: this.$route.query.id }).then(res => {
        this.detail = res;
        this.follow = res.list
      });
    },
     // 添加跟进
    followAdd(detail) {
      this.followDetail = detail
      this.hint('添加跟进','跟进')
    },
    // 添加跟进
    addFollowAdd(){
       const  {content,file_path } = this.followDetail
      addReturnVistTLog({ rvt_id: this.$route.query.id,content: content,iamges:file_path}).then(res => {
        this.$message({
            type: 'success',
            message: res.msg
          });
          this.getDetail();
      });
    },
    // 点击按钮处理
    getTextType(type){
      this.textType = type
      this.rateDetail.result_content = '';
      this.dialogVisible = true;

    },
    // 提交
     submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint(`处理${this.textType}`,'处理')
        } else {
          return false
        }
      })
    },
     // 提示
    hint(text,type) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if(type == "处理"){
            this.confirm(type);
          }else{
            this.addFollowAdd()
          }
          
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确认提交
    confirm(type){
      saveReturnVistT({ id:this.$route.query.id,...this.rateDetail,type:this.textType=="预警"?2:1 }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.dialogVisible = false;
          this.getDetail();
      });
      
       
    },
    // 返回
    back() {
      const query = {
        id: this.$route.query.clienId,
        detail:this.$route.query.detail,
        activeName: this.$route.query.types
      };
      this.$router.push({ name: "客户详情", query });
    }
    
  }
};
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
  .submit {
    text-align: center;
  }
  
  .mainBtn {
    margin: 50px 20px;
  }
}
.footerBtn{
    text-align: right;
  }
::v-deep .el-descriptions-item__label.is-bordered-label{
  background: #f9fafc;
}
.return {
  position: fixed;
  right: 20px;
  top: 71px;
  // background: #fff;
  z-index: 999;
  .backImg {
    margin-right: 14px;
    width: 32px;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}
</style>
